import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { PortalFrameMenuItem } from '../../types';

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    fontSize: theme.typography.body2.fontSize,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    transition: '0.3s ease',

    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    lineHeight: '24px'
  }
}));

interface TItemProps {
  entity: PortalFrameMenuItem
  isActive: boolean
  onClick: (value: string) => void
}

const Item: React.FC<TItemProps> = ({ entity, isActive, onClick }) => {
  const classes = useStyles();
  const itemClasses = isActive ? `${classes.item} ${classes.active}` : classes.item;

  return (
    <div className={itemClasses} onClick={() => onClick(entity.identifier)} data-order={entity.order}>
      <span className={classes.label}>{entity.name}</span>
    </div>
  )
}

export default Item
