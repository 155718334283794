import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '300px',
    margin: '0 auto',
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
}));

interface TLoginFormContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

const LoginFormContainer: React.FC<TLoginFormContainerProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <Typography variant='h4' component='h2' className={classes.marginBottom}>
        Welcome!
      </Typography>

      {children}
    </div>
  );
};

export default LoginFormContainer;
