import { makeAutoObservable } from 'mobx';
import jwtDecode from 'jwt-decode';
import InsightsApi, { LOGIN_RESPONSE_RESULT, TLoginData, TSetPasswordData } from '../lib/insightsApi';

interface IAuthTokenContent {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  scope: string;
  sub: string;
}

class AuthStore {
  private authenticated = false;
  private needPasswordChange = false;
  userId: string | null = null;

  constructor(private readonly api: InsightsApi) {
    makeAutoObservable(this);
    this.authenticated = !!this.getAuthToken();
    this.userId = this.getUserIdFromAuthToken();
  }

  private setAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  private setNeedPasswordChange(needPasswordChange: boolean) {
    this.needPasswordChange = needPasswordChange;
  }

  async login(data: TLoginData) {
    try {
      const response = await this.api.login(data);

      switch (response.result) {
        case LOGIN_RESPONSE_RESULT.LOGIN:
          const { authToken, refreshToken, deviceToken } = response;
          if (authToken && refreshToken && deviceToken) {
            localStorage.setItem('authToken', authToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('deviceToken', deviceToken);
            this.setAuthenticated(true);
            this.setNeedPasswordChange(false);
          }
          break;
        case LOGIN_RESPONSE_RESULT.RESET_PASSWORD:
          const { resetPasswordToken } = response;
          if (resetPasswordToken) {
            localStorage.setItem('resetPasswordToken', resetPasswordToken);
            this.setAuthenticated(false);
            this.setNeedPasswordChange(true);
          }
          break;
      }

      return response.result;
    } catch (err) {
      this.setAuthenticated(false);
      throw err;
    }
  }

  async logout() {
    this.api.logout();
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('deviceToken');
    localStorage.removeItem('resetPasswordToken');
    this.setAuthenticated(false);
    this.setNeedPasswordChange(false);
  }

  async setPassword(data: TSetPasswordData) {
    try {
      await this.api.setPassword(data);
      this.setNeedPasswordChange(false);
      this.setAuthenticated(false);
      localStorage.removeItem('resetPasswordToken');
    } catch (err) {
      this.setNeedPasswordChange(true);
      this.setAuthenticated(false);
      throw err;
    }
  }

  async sendRecoveryEmail(username: string) {
    try {
      await this.api.sendRecoveryEmail(username);
    } catch (err) {
      throw err;
    }
  }

  async requestMagicLink(username: string) {
    try {
      await this.api.requestMagicLink(username);
    } catch (err) {
      throw err;
    }
  }

  getAuthToken() {
    return localStorage.getItem('authToken');
  }

  getUserIdFromAuthToken() {
    const token = this.getAuthToken();
    if (token) {
      const decodedToken: IAuthTokenContent = jwtDecode(token);
      return decodedToken.sub;
    }

    return null;
  }

  getResetPasswordToken() {
    return localStorage.getItem('resetPasswordToken');
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isPasswordChangeNeeded() {
    return this.needPasswordChange;
  }
}

export default AuthStore;
