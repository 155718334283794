import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../components/Input';
import Button from '../../components/Button';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '300px',
    margin: `${theme.spacing(5)}px auto 0`,
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
}));

interface TEvent {
  target: { value: string; name: string };
}

interface RequestMagicLinkFormProps {
  onFormSubmit: (e: React.FormEvent) => void;
  onInputChange: (e: TEvent) => void;
  formValue: { email: string };
}

const RequestMagicLinkForm: React.FC<RequestMagicLinkFormProps> = ({
  formValue,
  onFormSubmit,
  onInputChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <form autoComplete='off' onSubmit={onFormSubmit} data-testid='request-magic-link-form'>
        <Grid container direction='column'>
          <FormControl className={classes.marginBottom}>
            <Input
              placeholder='E-mail'
              inputProps={{ 'aria-label': 'e-mail' }}
              type='email'
              name='email'
              value={formValue.email}
              onChange={onInputChange}
            />
          </FormControl>
        </Grid>
        <Button type='submit' fullWidth className={classes.marginBottom}>
          Send link to login
        </Button>
      </form>
    </div>
  );
};

export default RequestMagicLinkForm;
