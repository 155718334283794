import React from 'react';

const QuestionMark: React.FC = () => {
  return (
    <svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.97754 15.7949C3.70404 15.7949 0.227539 12.3182 0.227539 8.04492C0.227539 3.77167 3.70404 0.294922 7.97754 0.294922C12.251 0.294922 15.7275 3.77167 15.7275 8.04492C15.7275 12.3182 12.251 15.7949 7.97754 15.7949ZM7.97754 0.794922C3.98004 0.794922 0.727539 4.04717 0.727539 8.04492C0.727539 12.0427 3.98004 15.2949 7.97754 15.2949C11.975 15.2949 15.2275 12.0427 15.2275 8.04492C15.2275 4.04717 11.9753 0.794922 7.97754 0.794922Z' />
      <path d='M7.97754 13.5449C7.42604 13.5449 6.97754 13.0964 6.97754 12.5449C6.97754 11.9934 7.42604 11.5449 7.97754 11.5449C8.52904 11.5449 8.97752 11.9934 8.97752 12.5449C8.97752 13.0964 8.52904 13.5449 7.97754 13.5449ZM7.97754 12.0449C7.70179 12.0449 7.47754 12.2692 7.47754 12.5449C7.47754 12.8207 7.70179 13.0449 7.97754 13.0449C8.25329 13.0449 8.47752 12.8207 8.47752 12.5449C8.47752 12.2692 8.25329 12.0449 7.97754 12.0449Z' />
      <path d='M8.22749 9.54492H7.72751V7.79492H7.97751C9.21826 7.79492 10.2275 6.78542 10.2275 5.54492C10.2275 4.30442 9.21826 3.29492 7.97751 3.29492C7.08501 3.29492 6.25301 3.83668 5.90726 4.64343L5.44775 4.44641C5.871 3.45866 6.88776 2.79492 7.97751 2.79492C9.49376 2.79492 10.7275 4.02867 10.7275 5.54492C10.7275 6.97717 9.62724 8.15691 8.22749 8.28366V9.54492Z' />
    </svg>
  );
}

export default QuestionMark;
