import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const CtaButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.red.main,
    color: theme.palette.common.white,
    borderRadius: 100,
    border: `1px solid ${theme.red.main}`,
    height: '41px',
    '&:hover': {
      backgroundColor: theme.red.darkish,
      borderColor: theme.red.darkish,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.red.darkish,
      borderColor: theme.red.darkish,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    '&[disabled]': {
      boxShadow: 'none',
      color: theme.palette.common.white,
      backgroundColor: theme.gray.lighten,
      borderColor: theme.gray.lighten,
    },
  },
}))(Button);

export default CtaButton;
