import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { useInsightsContext } from '../../init';
import { UrlParams } from '../../types';
import { CONTENT_BACKGROUND } from '../../theme';

import Page from '../../layout/Page';
import Navigation from '../../components/Sidebar/Navigation';
import IframeContainer from '../../components/IframeContainer';
import KpiDefinition from '../../components/KpiDefinition';
import TermsAndConditions from '../../components/TermsAndConditions';
import SidebarLinks from '../../components/Sidebar/SidebarLinks';

const SIDEBAR_WIDTH = 260;

const useStyles = makeStyles((theme) => {
  const mainContent = {
    overflow: 'hidden',
    height: `calc(100vh - (${theme.sizes.headerHeight} + ${theme.sizes.footerHeight}))`,
    backgroundColor: CONTENT_BACKGROUND,
  };

  return {
    sidebar: {
      position: 'fixed',
      zIndex: 10, // needed otherwise will be hidden from `content` container
      width: `${SIDEBAR_WIDTH}px`,
      top: theme.sizes.headerHeight,
      bottom: theme.sizes.footerHeight,
      left: 0,
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    content: {
      position: 'relative',
      marginLeft: `${SIDEBAR_WIDTH}px`,
      ...mainContent,
    },
    kpi: {
      position: 'relative',
      ...mainContent,
    },
  };
});

const Main: React.FC = () => {
  const [isFullWidth, setIsFullWidth] = React.useState<boolean>(false);
  const store = useInsightsContext();
  const urlParams = useParams<UrlParams>();
  const classes = useStyles();

  React.useEffect(() => {
    if (!store.isLoaded) {
      /* eslint-disable @typescript-eslint/no-floating-promises */
      store.initialize(urlParams.category ?? '', urlParams.frame ?? '');
      /* eslint-enable @typescript-eslint/no-floating-promises */
    }
  }, []);

  React.useEffect(() => {
    if (store.kpiDefinitionUrl !== '' || store.isTermsAndConditionsOpen) {
      setIsFullWidth(true);
    } else {
      setIsFullWidth(false);
    }
  }, [store.kpiDefinitionUrl, store.isTermsAndConditionsOpen]);

  return (
    <Page>
      {!isFullWidth && (
        <div className={classes.sidebar}>
          <Navigation store={store} />
          <SidebarLinks store={store} />
        </div>
      )}
      <div className={isFullWidth ? classes.kpi : classes.content}>
        <KpiDefinition store={store} />
        <TermsAndConditions store={store} />
        <IframeContainer store={store} />
      </div>
    </Page>
  );
};

export default observer(Main);
