import { observable, makeObservable, computed, runInAction } from 'mobx';
import InsightsApi, { IPortalNavigationResponseData, IPortalNavigationItem } from '../lib/insightsApi';
import { PortalFrame, PortalFrameMenuItem } from '../types';

export interface IInsightsStore {
  initialize: (wantedCategory: string, wantedFrame: string) => Promise<boolean>;
  setSelectedCategory: (category: string) => void;
  onCategoryChange: (category: string) => Promise<boolean>;
  onFrameChange: (frame: string) => void;
  loadKPIDefinition: () => void;
  resetKPIDefinition: () => void;
  getUrl: () => string;
  resetStates: () => void;
  navigationCategories: IPortalNavigationItem[];
  selectedCategory: string;
  selectedPlatform: string;
  brandName: string;
  isLoaded: boolean;
  isInCategories: (query: string) => boolean;
  isInFrames: (query: string) => boolean;
  isReportHidden: boolean;
  frames: PortalFrameMenuItem[];
  selectedFrame: string;
  getNewFrame: (params?: {}) => Promise<PortalFrame | null>;
  kpiDefinitionUrl: string;
  kpiLoading: boolean;
  openTermsAndConditions: () => void;
  closeTermsAndConditions: () => void;
  isTermsAndConditionsOpen: boolean;
  getUserGuideUrl: () => Promise<string>;
  userGuideUrl: string;
}

export default class InsightsStore implements IInsightsStore {
  loaded: boolean = false;
  navigation: IPortalNavigationResponseData | null = null;
  category: string = '';
  platform: string = '';
  brand: string = '';
  frames: PortalFrameMenuItem[] = [];
  selectedFrame: string = '';
  kpiDefinitionUrl: string = '';
  kpiLoading: boolean = false;
  isTermsAndConditionsOpen: boolean = false;
  userGuideUrl: string = '';

  api: InsightsApi;

  constructor(api: InsightsApi) {
    makeObservable(this, {
      loaded: observable,
      navigation: observable,
      category: observable,
      platform: observable,
      brand: observable,
      isInCategories: observable,
      frames: observable,
      selectedFrame: observable,
      kpiDefinitionUrl: observable,
      kpiLoading: observable,
      isTermsAndConditionsOpen: observable,
      userGuideUrl: observable,
    });

    this.api = api;
  }

  async initialize(wantedCategory: string, wantedFrame: string): Promise<boolean> {
    this.resetStates();
    return await this.api.getPortalNavigation().then((res: IPortalNavigationResponseData | null) => {
      if (res != null) {
        this.setNavigation(res);
        this.setBrand(res.brand);
        this.setPlatform(res.platform_id);
        const category = this.getStartingCategory(wantedCategory, res);
        if (category !== '') {
          return this.onCategoryChange(category, wantedFrame);
        } else {
          this.setLoaded(true);
          return true;
        }
      } else {
        this.setNavigation(null);
        this.setLoaded(false);
        return false;
      }
    });
  }

  resetStates(): void {
    runInAction(() => {
      this.loaded = false;
      this.navigation = null;
      this.brand = '';
      this.platform = '';
      this.category = '';
      this.frames = [];
      this.selectedFrame = '';
      this.kpiDefinitionUrl = '';
      this.kpiLoading = false;
      this.userGuideUrl = '';
    });
  }

  private getStartingCategory(wantedCategory: string, res: IPortalNavigationResponseData): string {
    if (wantedCategory !== '' && this.isInCategories(wantedCategory.toLocaleUpperCase())) {
      return wantedCategory.toLocaleUpperCase();
    }
    const activeCategories = res.categories.filter((c) => c.enabled);
    if (activeCategories.length > 0) {
      // @ts-expect-error - prev if condition checks that there is an element
      return activeCategories.shift().id;
    }
    return '';
  }

  private setLoaded(flag: boolean): void {
    runInAction(() => {
      this.loaded = flag;
    });
  }

  private setNavigation(navigationData: IPortalNavigationResponseData | null): void {
    runInAction(() => {
      this.navigation = navigationData;
    });
  }

  async onCategoryChange(category: string, wantedFrame: string = ''): Promise<boolean> {
    this.resetKPIDefinition();
    this.setSelectedCategory(category);
    return await this.loadPortalFrames(wantedFrame);
  }

  onFrameChange(frame: string): void {
    this.resetKPIDefinition();

    if (!this.isInFrames(frame)) {
      throw new Error(`Unknown frame '${frame}'`);
    }
    runInAction(() => {
      this.selectedFrame = frame;
    });
  }

  getUrl(): string {
    if (this.selectedFrame === '') {
      return '/' + this.selectedCategory.toLocaleLowerCase();
    }
    return '/' + this.selectedCategory.toLocaleLowerCase() + '/' + this.selectedFrame.toLocaleLowerCase();
  }

  setSelectedCategory(category: string): void {
    if (!this.isInCategories(category)) {
      throw new Error(`Unknown category '${category}'`);
    }
    runInAction(() => {
      this.category = category;
    });
  }

  async getNewFrame(params?: {}): Promise<PortalFrame | null> {
    if (this.selectedFrame === '' || this.selectedCategory === '' || this.selectedPlatform === '') {
      return await Promise.resolve(null);
    }
    return await this.api
      .getFrame(this.selectedPlatform, this.selectedCategory, this.selectedFrame, params)
      .then((data) => {
        const frame: PortalFrame = {
          identifier: String(data?.id),
          url: String(data?.embed_url),
        };
        return frame;
      });
  }

  async loadKPIDefinition(): Promise<boolean> {
    this.resetKPIDefinition();
    runInAction(() => {
      this.kpiLoading = true;
    });
    return await this.api
      .getKPIFrameUrl()
      .then((url) => {
        if (url !== null) {
          runInAction(() => {
            this.kpiDefinitionUrl = url;
          });
          return true;
        }
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.kpiLoading = false;
        });
      });
  }

  resetKPIDefinition(): void {
    runInAction(() => {
      this.kpiDefinitionUrl = '';
    });
  }

  openTermsAndConditions(): void {
    runInAction(() => {
      this.isTermsAndConditionsOpen = true;
    });
  }

  closeTermsAndConditions(): void {
    runInAction(() => {
      this.isTermsAndConditionsOpen = false;
    });
  }

  private async loadPortalFrames(wantedFrame: string): Promise<boolean> {
    return await this.api.getFramesByCategory(this.selectedPlatform, this.selectedCategory).then((data) => {
      if (data == null) {
        runInAction(() => {
          this.frames = [];
          this.selectedFrame = '';
        });
        return false;
      }
      runInAction(() => {
        this.frames = data
          .sort((item1, item2) => {
            if (item1.menu_display_order === item2.menu_display_order) {
              return 1;
            }

            if (isNaN(item1.menu_display_order)) {
              return -1;
            }

            if (isNaN(item2.menu_display_order)) {
              return 1;
            }

            return item1.menu_display_order - item2.menu_display_order;
          })
          .map((frame) => {
            return {
              identifier: frame.id,
              menuIconName: frame.menu_icon_name,
              name: frame.menu_display_name,
              order: frame.menu_display_order,
              reportGroup: frame.report_group,
              reportGroupName: frame.report_group_display_name,
            };
          });
        this.selectedFrame =
          wantedFrame !== '' && this.isInFrames(wantedFrame.toLocaleUpperCase())
            ? wantedFrame.toLocaleUpperCase()
            : this.frames[0].identifier;
      });
      this.setLoaded(true);
      return true;
    });
  }

  private setBrand(brand: string): void {
    runInAction(() => {
      this.brand = brand;
    });
  }

  private setPlatform(platformId: string): void {
    runInAction(() => {
      this.platform = platformId;
    });
  }

  @computed
  get navigationCategories(): IPortalNavigationItem[] {
    return this.navigation?.categories ?? [];
  }

  get selectedCategory(): string {
    return this.category;
  }

  get selectedPlatform(): string {
    return this.platform;
  }

  get brandName(): string {
    return this.brand;
  }

  get isLoaded(): boolean {
    return this.loaded;
  }

  @computed
  get isReportHidden(): boolean {
    return this.kpiDefinitionUrl !== '';
  }

  isInCategories(query: string): boolean {
    return (
      this.navigationCategories.filter((category) => category.id === query && category.enabled).length > 0
    );
  }

  isInFrames(frame: string): boolean {
    return this.frames.filter((f) => f.identifier === frame).length > 0;
  }

  async getUserGuideUrl(): Promise<string> {
    if (this.userGuideUrl) return this.userGuideUrl;

    const data = await this.api.getUserGuide();
    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    const url = URL.createObjectURL(blob);
    this.userGuideUrl = url;
    return url;
  }
}
