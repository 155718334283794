import { AxiosInstance, AxiosResponse, Method } from 'axios';
import { AUTH_FLOW_URL } from './insightsApi';

const retryInProgress = { running: false };
export const maxTries = 5;

export type TProxyProviderFn = (
  url: string,
  data?: object,
  method?: Method,
  headers?: object,
  config?: object,
  tries?: number
) => Promise<AxiosResponse<any>>;

export default class RequestProxyFactory {
  baseUrl: string;
  axios: AxiosInstance;

  constructor(baseUrl: string, axios: AxiosInstance) {
    this.baseUrl = baseUrl;
    this.axios = axios;
  }

  provideProxy(): TProxyProviderFn {
    const proxy = async (
      url: string,
      data: object = {},
      method: Method = 'GET',
      headers: object = {},
      config: object = {},
      tries: number = 1
    ): Promise<any> => {
      const authToken = localStorage.getItem('authToken');
      const authorizationHeader = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const fullHeaders = { ...headers, ...authorizationHeader };

      return await this.axios
        .request({
          method,
          url: this.baseUrl + url,
          headers: fullHeaders,
          params: data,
          ...config,
        })
        .catch(async (e) => {
          if (e.response && e.response.status === 401 && !retryInProgress.running) {
            retryInProgress.running = true;

            return await refreshToken()
              .then(async () => {
                retryInProgress.running = false;
                if (tries >= maxTries) {
                  throw new Error('max retries reached for ' + this.baseUrl + url);
                }
                return await proxy(url, data, method, headers, config, ++tries);
              })
              .catch(() => {
                localStorage.removeItem('authToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('deviceToken');
                window.location.replace('/login');
              });
          }

          throw e;
        });
    };

    const refreshToken = async () => {
      const refreshToken = localStorage.getItem('refreshToken');
      const deviceToken = localStorage.getItem('deviceToken');

      try {
        const config = { data: { refreshToken, deviceToken } };
        const response = await proxy(`${AUTH_FLOW_URL}/refresh-token`, {}, 'POST', {}, config);

        let { data } = response;

        localStorage.setItem('authToken', data.authToken);
        localStorage.setItem('refreshToken', data.refreshToken);

        return data;
      } catch (error) {
        console.error(error);
        throw new Error('Failed to refresh token');
      }
    };

    return proxy;
  }
}
