import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Page from '../../layout/Page';
import RequestMagicLinkForm from './RequestMagicLinkForm';
import OTPLoginPageContainer from './OTPLoginPageContainer';
import Loader from '../../components/Loader';
import Link from '../../components/Link';
import ErrorMessage from '../../components/ErrorMessage';

import { UrlParams } from '../../types';
import { getSearchParameterWithoutEncoding } from '../../helpers';
import { useAuthContext, useInsightsContext } from '../../init';
import { LOGIN_ACTION_TYPES, TLoginData } from '../../lib/insightsApi';

interface TEvent {
  target: { value: string; name: string };
}

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
}));

const SuccessMessage = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.marginBottom}>
      A direct link to login has been successfully sent to your email.
    </Typography>
  );
};

const OTPLogin: React.FC = () => {
  const auth = useAuthContext();
  const history = useHistory();
  const location = useLocation();

  if (auth.isAuthenticated()) {
    history.push('/');
  }

  const insights = useInsightsContext();
  const params = useParams<UrlParams>();

  const [form, setForm] = useState({ email: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    // we don't use URLSearchParams for email, because it isn't encoded in url from emails
    const email = getSearchParameterWithoutEncoding('email', location.search);

    if (email) {
      setForm({ email });
    }

    if (token) {
      login(token);
    }
  }, []);

  const login = async (token: string) => {
    setError('');
    setLoading(true);

    const data: TLoginData = { token, type: LOGIN_ACTION_TYPES.MAGIC_LINK };

    try {
      await auth.login(data);
      await insights.initialize(params.category ?? '', params.frame ?? '');
      history.push('/');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const requestMagicLink = async (email: string) => {
    setError('');
    setLoading(true);

    try {
      await auth.requestMagicLink(email);
      setIsSent(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRequestFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    requestMagicLink(form.email);
  };

  const handleInputChange = (e: TEvent): void => {
    setForm({ ...form, [e.target.name]: e.target.value.trim() });
  };

  return (
    <Page>
      <OTPLoginPageContainer>
        {isSent ? (
          <SuccessMessage />
        ) : (
          <React.Fragment>
            <Typography variant='body2'>
              Please enter your email address to receive a direct link to login.
            </Typography>
            <RequestMagicLinkForm
              formValue={form}
              onInputChange={handleInputChange}
              onFormSubmit={handleRequestFormSubmit}
            />
          </React.Fragment>
        )}

        <ErrorMessage error={error} />

        <Link to='/login'>Back to login page</Link>
      </OTPLoginPageContainer>

      <Loader open={loading} />
    </Page>
  );
};

export default OTPLogin;
