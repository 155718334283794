import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { WHITE, RED } from '../../theme';

const useStyles = makeStyles((theme) => ({
  svg: {
    display: 'block',
    width: '100%',
    height: 'auto'
  }
}));

interface TDeliveryHeroLogoProps {
  primary?: boolean
};

const DHTextLogo: React.FC<TDeliveryHeroLogoProps> = ({ primary }) => {
  const classes = useStyles();
  const color = primary != null ? RED : WHITE;

  return (
    <svg className={classes.svg} width='572' height='95' viewBox='0 0 572 95' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M100.758 2.72849C84.9623 -3.7206 67.7305 1.24023 56.9606 13.3943L15.3171 59.7781C14.8385 60.5222 15.0778 61.2663 15.7958 61.2663L26.805 62.0105C27.7623 62.0105 27.7623 62.7546 27.2837 63.4987L0.239307 93.7598C-0.239354 94.2559 0.239307 95 0.957298 95L39.7288 82.3499C40.4468 82.1019 41.1648 82.846 40.9255 83.5901L35.6602 93.0157C35.4209 93.5118 35.8996 94.2559 36.6175 94.2559L92.3816 81.3577C105.784 79.1253 117.751 69.9478 123.016 56.0575C130.914 34.7259 120.862 11.1619 100.758 2.72849ZM106.741 44.6475L94.2962 49.8564L93.8176 50.1045L90.9456 64.4909C90.7062 64.987 90.2276 64.987 89.7489 64.7389L80.6544 53.577L29.4376 76.3969C29.4376 76.3969 29.4376 76.3969 29.1983 76.3969C28.959 76.3969 28.959 76.1488 28.959 76.1488C28.959 76.1488 28.959 75.9008 29.1983 75.9008L73.4744 41.423L67.9698 28.2768C67.7305 27.7807 68.2092 27.0366 68.9272 27.2846L82.569 30.7572L93.0996 21.0836C93.5782 20.8355 94.0569 21.0836 94.2962 21.5797L95.2535 36.2141L107.459 43.6554C107.699 43.6554 107.459 44.3995 106.741 44.6475Z' fill={color} />
        <path d='M250.34 73.1723H237.655L247.707 24.8042L261.588 19.5953L250.34 73.1723Z' fill={color} />
        <path d='M216.116 59.5301C216.116 59.7781 215.876 60.5222 215.876 60.7703C215.876 63.0026 217.312 63.7468 221.141 63.7468C224.492 63.7468 229.279 62.7546 232.151 61.7624V71.6841C228.561 72.9243 223.056 73.6684 218.509 73.6684C207.978 73.6684 202.952 70.6919 202.952 60.7703C202.952 51.0966 207.26 35.9661 224.013 35.9661C234.544 35.9661 237.895 40.9269 237.895 46.1358C237.895 53.329 232.151 59.034 216.116 59.5301ZM223.056 45.6397C219.945 45.6397 218.27 48.3682 217.552 51.3447C223.535 51.3447 225.449 49.3603 225.449 47.8721C225.449 46.3838 224.492 45.6397 223.056 45.6397Z' fill={color} />
        <path d='M273.555 34.4779C269.247 34.4779 266.375 31.5014 266.375 27.5327C266.375 22.3239 269.965 19.3474 274.273 19.3474C278.581 19.3474 281.453 22.3239 281.453 26.0445C281.453 31.7494 277.863 34.4779 273.555 34.4779Z' fill={color} />
        <path d='M264.7 37.4543H277.623L271.162 72.6763H257.759L262.546 47.8721' fill={color} />
        <path d='M315.438 37.4543C310.89 49.3603 305.864 60.7702 299.642 72.6763H283.367C281.213 61.5144 280.495 49.8564 280.735 37.4543H293.898C293.659 43.1593 293.659 49.1123 294.137 54.5692C294.137 56.0575 294.377 57.5457 294.377 58.7859C294.855 57.2977 295.573 56.0575 296.291 54.5692C298.445 48.8642 300.599 42.4152 302.274 37.4543H315.438Z' fill={color} />
        <path d='M326.208 59.5301C326.208 59.7781 325.968 60.5222 325.968 60.7703C325.968 63.0026 327.404 63.7468 331.234 63.7468C334.584 63.7468 339.371 62.7546 342.243 61.7624V71.6841C338.653 72.9243 333.148 73.6684 328.601 73.6684C318.07 73.6684 313.044 70.6919 313.044 60.7703C313.044 51.0966 317.352 35.9661 334.106 35.9661C344.636 35.9661 347.987 40.9269 347.987 46.1358C347.987 53.329 342.243 59.034 326.208 59.5301ZM333.148 45.6397C330.037 45.6397 328.362 48.3682 327.644 51.3447C333.627 51.3447 335.541 49.3603 335.541 47.8721C335.541 46.3838 334.584 45.6397 333.148 45.6397Z' fill={color} />
        <path d='M373.356 49.8563C372.638 49.6083 371.202 49.3603 370.244 49.3603C367.372 49.3603 364.979 52.3368 364.022 58.0417L361.389 72.6762H348.226L354.927 37.4543H364.74L364.979 42.1671C368.09 38.1984 370.484 36.2141 374.074 36.2141C375.749 36.2141 376.706 36.4621 377.185 36.4621L373.356 49.8563Z' fill={color} />
        <path d='M414.76 37.4543C409.016 53.0809 403.751 64.7389 398.485 72.6762C391.066 84.3342 384.365 87.0627 377.424 87.0627C375.51 87.0627 373.356 86.5666 372.159 86.0705L374.313 75.9008H378.86C381.014 75.9008 382.211 74.9086 383.647 72.9243C381.014 63.4987 379.818 50.1044 380.057 37.7024H393.459C393.22 43.4073 393.22 49.3603 393.699 54.8172C393.699 56.3055 393.938 57.7937 393.938 59.282C394.417 57.7937 395.135 56.5535 395.853 54.8172C398.007 49.1123 400.161 42.9112 401.597 37.7024H414.76V37.4543Z' fill={color} />
        <path d='M465.498 72.6763H451.617L454.967 54.8173H443.24L439.89 72.6763H425.769L434.624 25.7964H448.745L445.394 42.4152H457.361L460.472 25.7964H474.353L465.498 72.6763Z' fill={color} />
        <path d='M486.08 59.5301C486.08 59.7781 485.841 60.5222 485.841 60.7703C485.841 63.0026 487.277 63.7468 491.106 63.7468C494.457 63.7468 499.243 62.7546 502.115 61.7624V71.6841C498.525 72.9243 493.021 73.6684 488.474 73.6684C477.943 73.6684 472.917 70.6919 472.917 60.7703C472.917 51.0966 477.225 35.9661 493.978 35.9661C504.509 35.9661 507.859 40.9269 507.859 46.1358C507.859 53.329 502.115 59.034 486.08 59.5301ZM493.021 45.6397C489.909 45.6397 488.234 48.3682 487.516 51.3447C493.499 51.3447 495.414 49.3603 495.414 47.8721C495.414 46.3838 494.457 45.6397 493.021 45.6397Z' fill={color} />
        <path d='M533.228 49.8563C532.51 49.6083 531.074 49.3603 530.117 49.3603C527.245 49.3603 524.852 52.3368 523.894 58.0417L521.262 72.6762H508.099L514.8 37.4543H524.612L524.852 42.1671C527.963 38.1984 530.356 36.2141 533.946 36.2141C535.622 36.2141 536.579 36.4621 537.058 36.4621L533.228 49.8563Z' fill={color} />
        <path d='M549.982 73.9165C539.691 73.9165 534.665 69.4518 534.665 60.2742C534.665 50.1045 539.451 36.4622 556.683 36.4622C566.974 36.4622 572 41.175 572 50.1045C572 60.2742 567.214 73.9165 549.982 73.9165ZM555.008 47.128C549.742 47.128 548.306 55.5614 548.306 59.2821C548.306 62.2586 549.503 63.2507 551.896 63.2507C557.162 63.2507 558.598 54.8173 558.598 51.0967C558.598 47.8721 557.401 47.128 555.008 47.128Z' fill={color} />
        <path d='M186.917 37.7024L195.772 29.0209C192.422 26.7886 187.635 25.7964 181.652 25.7964H178.78H164.899L156.043 72.6763H169.925H173.754C194.097 72.6763 202.234 57.7938 202.234 42.4152C202.234 38.4465 201.516 35.222 199.841 32.7416L186.917 37.7024ZM174.472 61.5144H171.839L176.387 36.7102H179.977C185.481 36.7102 187.396 40.1828 187.396 44.6476C187.874 54.0732 182.848 61.5144 174.472 61.5144Z' fill={color} />
      </g>
    </svg>
  );
};

const DHCometLogo: React.FC<TDeliveryHeroLogoProps> = ({ primary }) => {
  const classes = useStyles();
  const color = primary != null ? RED : WHITE;

  return (
    <svg className={classes.svg} viewBox='0 0 128 95' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M100.758 2.72849C84.9623 -3.7206 67.7305 1.24023 56.9606 13.3943L15.3171 59.7781C14.8385 60.5222 15.0778 61.2663 15.7958 61.2663L26.805 62.0105C27.7623 62.0105 27.7623 62.7546 27.2837 63.4987L0.239307 93.7598C-0.239354 94.2559 0.239307 95 0.957298 95L39.7288 82.3499C40.4468 82.1019 41.1648 82.846 40.9255 83.5901L35.6602 93.0157C35.4209 93.5118 35.8996 94.2559 36.6175 94.2559L92.3816 81.3577C105.784 79.1253 117.751 69.9478 123.016 56.0575C130.914 34.7259 120.862 11.1619 100.758 2.72849ZM106.741 44.6475L94.2962 49.8564L93.8176 50.1045L90.9456 64.4909C90.7062 64.987 90.2276 64.987 89.7489 64.7389L80.6544 53.577L29.4376 76.3969C29.4376 76.3969 29.4376 76.3969 29.1983 76.3969C28.959 76.3969 28.959 76.1488 28.959 76.1488C28.959 76.1488 28.959 75.9008 29.1983 75.9008L73.4744 41.423L67.9698 28.2768C67.7305 27.7807 68.2092 27.0366 68.9272 27.2846L82.569 30.7572L93.0996 21.0836C93.5782 20.8355 94.0569 21.0836 94.2962 21.5797L95.2535 36.2141L107.459 43.6554C107.699 43.6554 107.459 44.3995 106.741 44.6475Z' fill={color} />
      </g>
    </svg>
  );
};

export {
  DHTextLogo,
  DHCometLogo
};
