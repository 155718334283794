import React from 'react';
import ReactDOM from 'react-dom';
import MuiTheme from './theme';
import App from './App';
import { init } from './init';

const render = async (Component: React.FC) => {
  // Initialize the entire app
  await init();

  ReactDOM.render(
    <React.StrictMode>
      <MuiTheme>
        <Component />
      </MuiTheme>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render(App);
