import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}));

const NewPasswordDisclaimer: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant='subtitle1' component='h3' className={classes.subtitle}>
        To continue you must set a new password
      </Typography>

      <Typography variant='body2'>
        Please note that the password must be at least <strong>8 characters long</strong> and contain at least{' '}
        <strong>one number</strong>, <strong>one special character</strong>, <strong>one uppercase</strong> and{' '}
        <strong>one lowercase letter</strong>.
      </Typography>
    </>
  );
};

export default NewPasswordDisclaimer;
