import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

interface TLoaderProps { open: boolean }

const useStyles = makeStyles((theme) => ({
  loader: {
    zIndex: theme.zIndex.drawer + 1
  }
}))

const Loader: React.FC<TLoaderProps> = ({ open }) => {
  const classes = useStyles()

  return (
    <Backdrop
      open={open}
      className={classes.loader}
    >
      <CircularProgress size={60} color='primary' />
    </Backdrop>
  )
}

export default Loader
