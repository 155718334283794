import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { PortalFrame } from '../../types';

const useStyles = makeStyles(() => ({
  frame: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  hidden: {
    display: 'none',
  },
}));

interface IframeProps {
  frame: PortalFrame | null;
  isHidden: Boolean;
  className?: string;
}

const Iframe = React.forwardRef((props: IframeProps, ref: React.Ref<HTMLIFrameElement>) => {
  const { frame, isHidden, className } = props;
  const classes = useStyles();
  const iframeClasses = [classes.frame, isHidden, className].filter((item) => !!item).join(' ');

  if (!frame) {
    return null;
  }

  return (
    <iframe
      ref={ref}
      id={frame.identifier}
      title={frame.identifier}
      src={frame.url}
      className={iframeClasses}
    />
  );
});

export default observer(Iframe);
