import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IInsightsStore } from '../../stores/insightsStore';
import { observer } from 'mobx-react';
import Button from '../Button';
import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
  },
  frame: {
    width: '100%',
    height: 'calc(100% - 70px)',
    border: 'none',
  },
  btnWrap: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  btn: {
    margin: '0 auto',
  },
}));

interface KpiDefinitionProps {
  store: IInsightsStore;
}

const KpiDefinition: React.FC<KpiDefinitionProps> = ({ store }) => {
  const classes = useStyles();
  const [frame, setFrame] = useState<string>('');

  const close = (): void => {
    store.resetKPIDefinition();
  };

  useEffect(() => {
    setFrame(store.kpiDefinitionUrl);
  }, [store.kpiDefinitionUrl]);

  if (store.kpiLoading) {
    return <Loader open />;
  }

  if (frame !== '') {
    return (
      <div className={classes.wrap}>
        <iframe title='kpi definitions' src={frame} className={classes.frame} />
        <div className={classes.btnWrap}>
          <Button type='button' onClick={close} className={classes.btn}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

export default observer(KpiDefinition);
