type getSearchParameterWithoutEncodingType = (name: string, url: string) => string | null;

export const getSearchParameterWithoutEncoding: getSearchParameterWithoutEncodingType = (
  name,
  url = window.location.search
) => {
  const regex = new RegExp(`[?&]${name}(=(?<value>[^&#]*)|&|#|$)`);
  let results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }
  return results[2];
};
