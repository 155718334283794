import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NewPasswordDisclaimer from './NewPasswordDisclaimer';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '470px',
    margin: '0 auto',
    textAlign: 'center',
  },
  formContainer: {
    width: '300px',
    margin: `${theme.spacing(5)}px auto 0`,
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
}));

interface TSetPasswordWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

const SetPasswordWrapper: React.FC<TSetPasswordWrapperProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <NewPasswordDisclaimer />

      <div className={classes.formContainer}>{children}</div>
    </div>
  );
};

export default SetPasswordWrapper;
