import axios from 'axios';
import { createContext, useContext } from 'react';
import { reaction } from 'mobx';
import InsightsStore, { IInsightsStore } from './stores/insightsStore';
import AuthStore from './stores/authStore';
import FwFStore from './stores/fwfStore';
import InsightsApi from './lib/insightsApi';
import RequestProxyFactory from './lib/RequestProxyFactory';

const INSIGHTS_API_URL = process.env.REACT_APP_API_URL as string;

export const GOOGLE_DIMENSION_USER_EMAIL = process.env.REACT_APP_GOOGLE_DIMENSION_USER_EMAIL as string;

const api = new InsightsApi(new RequestProxyFactory(INSIGHTS_API_URL, axios).provideProxy());
const insightsStore = new InsightsStore(api);
const insightsContext = createContext(insightsStore);
export const useInsightsContext = (): IInsightsStore => {
  const insights = useContext(insightsContext);

  return insights;
};

const authStore = new AuthStore(api);
export const authStoreContext = createContext(authStore);
export const useAuthContext = (): AuthStore => {
  const auth = useContext(authStoreContext);
  return auth;
};

const fwfStore = new FwFStore(authStore);
export const fwfStoreContext = createContext(fwfStore);
export const useFwFContext = (): FwFStore => {
  const fwf = useContext(fwfStoreContext);
  return fwf;
};

export const init = () => {
  reaction(
    () => ({
      isAuthenicated: authStore.isAuthenticated(),
    }),
    ({ isAuthenicated }) => {
      if (isAuthenicated) {
        fwfStore.init();
        fwfStore.getFeatureFlags();
      } else {
        fwfStore.clearUser();
      }
    },
    { fireImmediately: true }
  );
};
