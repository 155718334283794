import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoginPageContainer from '../Login/LoginPageContainer';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: '470px',
    margin: '0 auto',
    textAlign: 'center',
  },
}));

interface TRecoveryPageContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

const RecoveryPageContainer: React.FC<TRecoveryPageContainerProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <LoginPageContainer>
      <div className={classes.container}>{children}</div>
    </LoginPageContainer>
  );
};

export default RecoveryPageContainer;
