import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary
  }
}))

interface TCustomLinkProps {
  children: React.ReactNode
  to: string
}

const CustomLink: React.FC<TCustomLinkProps> = ({ children, to }) => {
  const classes = useStyles()

  return (
    <Typography align='center'><Link to={to} className={classes.link}>{children}</Link></Typography>
  )
}

export default CustomLink
