import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthContext } from '../../init';

import Header from '../Header';
import Footer from '../Footer';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - (${theme.sizes.headerHeight} + ${theme.sizes.footerHeight}))`
  }
}));

interface TPageProps {
  children: React.ReactNode | React.ReactNode[]
}

const Page: React.FC<TPageProps> = ({ children }) => {
  const classes = useStyles();
  const auth = useAuthContext();
  const isAuthenticated = auth.isAuthenticated();

  return (
    <div>
      { isAuthenticated && <Header /> }
      <div className={classes.page}>
        {children}
      </div>
      { isAuthenticated && <Footer /> }
    </div>
  );
};

export default Page;
