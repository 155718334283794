import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../components/Input';
import Button from '../../components/Button';

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
  checkbox: {
    marginRight: 0,
    marginBottom: theme.spacing(5),
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,

    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
}));

interface TEvent {
  target: { value: string; name: string };
}

interface TFormProps {
  onInputChange: (e: TEvent) => void;
  onSubmit: (e: React.FormEvent) => void;
  onTermsAndConditionsClick: () => void;
}

const SetPasswordForm: React.FC<TFormProps> = ({ onInputChange, onSubmit, onTermsAndConditionsClick }) => {
  const classes = useStyles();
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <form autoComplete='off' onSubmit={onSubmit} data-testid='new-password-form'>
      <Grid container direction='column'>
        <FormControl className={classes.marginBottom}>
          <Input
            placeholder='New Password'
            inputProps={{ 'aria-label': 'new-password' }}
            type='password'
            name='new-password'
            onChange={onInputChange}
          />
        </FormControl>
        <FormControl className={classes.marginBottom}>
          <Input
            placeholder='Repeat New Password'
            inputProps={{ 'aria-label': 'repeat-new-password' }}
            type='password'
            name='repeat-new-password'
            onChange={onInputChange}
          />
        </FormControl>
        <FormControlLabel
          className={classes.checkbox}
          control={<Checkbox checked={isAccepted} onChange={(e, accepted) => setIsAccepted(accepted)} />}
          label={
            <span>
              By signing in, you are agreeing to&nbsp;the&nbsp;
              <Link onClick={onTermsAndConditionsClick}>Terms and Conditions</Link>{' '}
              of&nbsp;the&nbsp;Insights&nbsp;Platform
            </span>
          }
        />
      </Grid>
      <Button type='submit' fullWidth className={classes.marginBottom} disabled={!isAccepted}>
        Change Password
      </Button>
    </form>
  );
};

export default SetPasswordForm;
