import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

const ApplyButton = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.15,
    color: theme.palette.common.white,
    padding: '10px 24px',
    border: 0,
    borderRadius: 4,
    backgroundColor: theme.red.main,
    textTransform: 'none',

    '&:hover': {
      backgroundColor: theme.red.darkish,
      borderColor: theme.red.darkish,
    },

    '&[disabled]': {
      color: theme.palette.common.white,
      background: theme.gray.lighten,
    },
  },
}))(Button);

const ResetButton = withStyles((theme) => ({
  root: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.15,
    padding: '10px 0',
    color: theme.gray.main,
    textTransform: 'none',

    '&:hover': {
      background: 'none',
    },

    '&[disabled]': {
      color: theme.gray.light,

      '& .MuiButton-startIcon': {
        fill: theme.gray.light,
      },
    },
  },
  startIcon: {
    width: 8,
    height: 8,
    marginRight: 10,
    fill: theme.gray.main,
  },
}))(Button);

const CloseIcon = () => (
  <svg viewBox='0 0 9 9' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8.29165 1.47208L7.5279 0.708328L4.49998 3.73625L1.47206 0.708328L0.708313 1.47208L3.73623 4.49999L0.708313 7.52791L1.47206 8.29166L4.49998 5.26374L7.5279 8.29166L8.29165 7.52791L5.26373 4.49999L8.29165 1.47208Z' />
  </svg>
);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.common.white,
    padding: '12px 20px',
    marginBottom: -20,
    position: 'relative',

    [theme.breakpoints.up(1028)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },

    [theme.breakpoints.up(1460)]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 400,
    margin: 0,
  },
  controls: {
    display: 'flex',
    flexShrink: 0,

    '& > * + *': {
      marginLeft: 16,
    },
  },
}));

interface IFiltersControlsProps {
  title?: string;
  onReset: () => void;
  onApply: () => void;
  hasChanges: boolean;
  isDefault: boolean;
}

const FiltersControls: React.FC<IFiltersControlsProps> = ({
  title,
  onReset,
  onApply,
  hasChanges,
  isDefault,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      {title && <h1 className={classes.title}>{title}</h1>}
      <div className={classes.controls}>
        <ApplyButton disabled={!hasChanges} onClick={onApply}>
          Apply filters
        </ApplyButton>
        <ResetButton startIcon={<CloseIcon />} disabled={isDefault} onClick={onReset}>
          Clear all
        </ResetButton>
      </div>
    </div>
  );
};

export default observer(FiltersControls);
