import React from 'react';

const UserGuideIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <g transform="translate(672, 672)">
            <g>
                <rect x="-658.492" y="-668.662" width="2" height="60"/>
            </g>
            <g>
                <path d="M-621.675-607.662h-46.817v-62h46.817c2.857,0,5.183,2.243,5.183,5v52
                    C-616.492-609.905-618.817-607.662-621.675-607.662z M-666.492-609.662h44.817c1.755,0,3.183-1.346,3.183-3v-52
                    c0-1.654-1.428-3-3.183-3h-44.817V-609.662z"/>
            </g>
            <g>
                <rect x="-612.492" y="-660.662" width="2" height="8"/>
            </g>
            <g>
                <rect x="-612.492" y="-644.662" width="2" height="8"/>
            </g>
            <g>
                <path d="M-637.492-640.662c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S-634.735-640.662-637.492-640.662z
                    M-637.492-648.662c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S-635.838-648.662-637.492-648.662z"/>
            </g>
            <g>
                <path d="M-626.492-627.662h-22v-1c0-4.963,4.037-9,9-9h4c4.963,0,9,4.037,9,9V-627.662z M-646.421-629.662h17.857
                    c-0.486-3.388-3.408-6-6.929-6h-4C-643.013-635.662-645.935-633.05-646.421-629.662z"/>
            </g>
        </g>
    </svg>
  );
}

export default UserGuideIcon;
