import { observable, when, autorun } from 'mobx';
import FWFMain, {
  IFWFParams,
  FWFRegions,
  FWFApiVersion,
  VariationFallbackType,
  VariationFallback,
} from '@deliveryhero/fwf-sdk-javascript';
import AuthStore from './authStore';

declare type VariationExplanation = {
  kind: string;
  from: string;
};

declare type VariationTrackInfo = {
  flagEnabled: boolean;
  flagType: string;
  trackerServices: Array<string>;
  variationName: string;
};

declare type Variation = {
  variation: boolean;
  abTest: boolean;
  explanation: VariationExplanation;
  relevantContext: string;
  trackInfo?: VariationTrackInfo;
};

export declare type VariationMap<N extends string = string> = Record<N, Variation>;

interface IFWFMainConstructor {
  new (fwfParams: IFWFParams): FWFMain;
}

export const FWF_FLAGS = {
  SHOW_CTA_FILTERS_BUTTONS: 'use-custom-filters-buttons', // show cta on FE and change iframes theme on BE
};

const FWF_ENVIRONMENT_TOKEN = process.env.REACT_APP_FWF_ENVIRONMENT_TOKEN as string;
const FwFParams: IFWFParams = {
  region: FWFRegions.EU,
  apiVersion: FWFApiVersion.V3,
  environmentToken: FWF_ENVIRONMENT_TOKEN,
  user: {
    userId: '' as string,
  },
};

export default class FwFStore {
  authStore: AuthStore;
  FWFMain: IFWFMainConstructor;
  ff: Record<string, string | boolean>;

  @observable mainFwfClient: FWFMain;
  @observable private ready: boolean;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
    this.FWFMain = FWFMain;
    this.ff = {};
  }

  async init() {
    autorun(() => {
      const userId = this.authStore.getUserIdFromAuthToken();

      this.mainFwfClient = new this.FWFMain({
        ...FwFParams,
        user: { userId },
      });
      this.ready = true;
    });
  }

  clearUser() {
    this.mainFwfClient.clearUser();
  }

  async getFeatureFlags() {
    await when(() => this.ready);
    const value = await this.getVariationValue(FWF_FLAGS.SHOW_CTA_FILTERS_BUTTONS, false);
    this.ff = { [FWF_FLAGS.SHOW_CTA_FILTERS_BUTTONS]: value };
  }

  /**
   * Wrapper around FWF getVariation that creates variation fallback from value (not from `VariationFallback` class)
   * @param name key of the flag
   * @param fallbackValue fallback value when fetching fails
   * @param forced force http request to HTTP server
   */
  async getVariation<T extends string>(
    name: T,
    fallbackValue: boolean | string = false,
    forced: boolean = false
  ): Promise<VariationMap<T>> {
    const fallBack = new VariationFallback(VariationFallbackType.FROM_VALUE);
    fallBack.setValue(fallbackValue);
    await when(() => this.ready);
    return this.mainFwfClient.getVariation(name, fallBack, forced);
  }

  /**
   * Wrapper around FWF getVariation that abstracts its response format and directly returns the variation value
   * @param name key of the flag
   * @param fallBackValue fallback value when fetching fails
   * @param forced force http request to HTTP server
   */
  async getVariationValue<T extends string>(
    name: T,
    fallBackValue?: boolean | string,
    forced?: boolean
  ): Promise<boolean | string> {
    try {
      const result = await this.getVariation(name, fallBackValue, forced);
      return result[name].variation;
    } catch (fallback: any) {
      return fallback && fallback.variation !== undefined ? fallback.variation : false;
    }
  }
}
