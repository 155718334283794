import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { useInsightsContext } from '../../init';

import FooterLinks from './FooterLinks';

const useStyles = makeStyles((theme) => ({
  footer: {
    height: theme.sizes.footerHeight,
    backgroundColor: theme.palette.text.primary,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    justifyContent: 'flex-end'
  },
  comet: {
    width: '50px'
  }
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const store = useInsightsContext();

  return (
    <Grid container alignItems='center' className={classes.footer}>
      <Grid item>
        <FooterLinks store={store} />
      </Grid>
    </Grid>
  );
};

export default Footer;
