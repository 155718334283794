import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Page from '../../layout/Page';
import ErrorMessage from '../../components/ErrorMessage';
import TermsAndConditions from '../../components/TermsAndConditions';
import Loader from '../../components/Loader';

import LoginPageContainer from '../Login/LoginPageContainer';
import SetPasswordWrapper from './SetPasswordWrapper';
import SetPasswordForm from './SetPasswordForm';

import { RESET_PASSWORD_SOURCE, TSetPasswordData } from '../../lib/insightsApi';
import { useAuthContext, useInsightsContext } from '../../init';

interface TEvent {
  target: { value: string; name: string };
}

const SetPassword: React.FC = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [newPass, setNewPass] = useState<string>('');
  const [repeatedPass, setRepeatedPass] = useState<string | null>(null);
  const [token, setToken] = useState<string>('');
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [source, setSource] = useState<string>(RESET_PASSWORD_SOURCE.COGNITO);

  const auth = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const insights = useInsightsContext();

  if (auth.isAuthenticated()) {
    history.push('/');
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const email = params.get('email');
    const resetPasswordToken = auth.getResetPasswordToken();

    if (email) {
      setUsername(decodeURIComponent(email));
    }

    if (token) {
      setToken(token);
      setSource(RESET_PASSWORD_SOURCE.KEYMAKER);
      return;
    }

    if (resetPasswordToken) {
      setToken(resetPasswordToken);
      return;
    }

    return history.push('/login');
  }, []);

  const setNewPasswordAction = async () => {
    if (newPass !== repeatedPass) {
      setError('Passwords do not match');
      return;
    }

    setError('');
    setLoading(true);

    const data: TSetPasswordData = { username, newPassword: newPass, token, source };

    try {
      await auth.setPassword(data);
      history.push('/login#password-changed');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNewPasswordInputChange = (e: TEvent): void => {
    if (e.target.name === 'new-password') {
      setNewPass(e.target.value.trim());
    } else {
      setRepeatedPass(e.target.value.trim());
    }
  };

  const onSetNewPasswordFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setNewPasswordAction();
  };

  const openTermsAndConditions = (): void => {
    insights.openTermsAndConditions();
  };

  return (
    <Page>
      <LoginPageContainer>
        <SetPasswordWrapper>
          <SetPasswordForm
            onInputChange={handleNewPasswordInputChange}
            onSubmit={onSetNewPasswordFormSubmit}
            onTermsAndConditionsClick={openTermsAndConditions}
          />
          <ErrorMessage error={error} />
        </SetPasswordWrapper>

        <TermsAndConditions store={insights} />
      </LoginPageContainer>

      <Loader open={loading} />
    </Page>
  );
};

export default SetPassword;
