import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IInsightsStore } from '../../stores/insightsStore';
import Button from '../Button';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
  },
  frame: {
    width: '100%',
    height: 'calc(100% - 70px)',
    border: 'none',
    padding: '50px 160px',
    overflow: 'auto',
  },
  btnWrap: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  btn: {
    margin: '0 auto',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(3),
    textAlign: 'justify',
  },
}));

interface TermsAndConditionsProps {
  store: IInsightsStore;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ store }) => {
  const classes = useStyles();

  const close = (): void => {
    store.closeTermsAndConditions();
  };

  if (!store.isTermsAndConditionsOpen) return null;

  return (
    <div className={classes.wrap} data-testid='terms-and-conditions'>
      <div className={classes.frame}>
        <Typography variant='h3' color='primary' className={classes.title}>
          Terms & Conditions
        </Typography>

        <div className={classes.paragraph}>
          <Typography variant='body2'>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS PLATFORM. BY USING OUR PLATFORM,
            YOU CONFIRM THAT YOU ACCEPT THESE TERMS OF USE AND THAT YOU AGREE TO COMPLY WITH THEM.
          </Typography>
        </div>

        <div className={classes.paragraph}>
          <Typography variant='h6'>You must keep your account details safe</Typography>
          <Typography variant='body2'>
            User identification code, password or any other piece of information, being part of your security
            procedures shall be treated confidential. You must not disclose it to any third party. We have the
            right to disable any user identification code or password at any time, if in our reasonable opinion
            you have failed to comply with any of the provisions of these terms of use. If you know or suspect
            that anyone other than you knows your user identification code or password, you must promptly
            notify us at dh_vt_data_insights@deliveryhero.com
          </Typography>
        </div>

        <div className={classes.paragraph}>
          <Typography variant='h6'>Do not rely on information on this platform</Typography>
          <Typography variant='body2'>
            The content on our platform is provided for general information only. It is not intended to amount
            to advice on which you should rely. You must obtain professional or specialist advice before
            taking, or refraining from, any action on the basis of the content on our platform. Although we
            make reasonable efforts to update the information on our platform, we make no representations,
            warranties or guarantees, whether express or implied, that the content on our site is accurate,
            complete or up to date.
          </Typography>
        </div>

        <div className={classes.paragraph}>
          <Typography variant='h6'>Our duty of confidentiality</Typography>
          <Typography variant='body2'>
            Any data and reports displayed on our platform or any information being used for analyzing such
            data is treated as strictly confidential and shall not be used for the benefit of any third
            parties. We are committed to ensure that our employees who are involved in analyzing and reporting
            the data provided on our platform are bound by the confidentiality obligations according to the
            applicable rules and standards.
          </Typography>
        </div>

        <div className={classes.paragraph}>
          <Typography variant='h6'>Our responsibility for loss or damage suffered by you</Typography>
          <Typography variant='body2'>
            We will not be liable to you for any loss or damage arising under or in connection with:use of or
            inability to use our site; or use of or reliance on any content displayed on our site. In
            particular, we will not be liable for:loss of profits, sales, business, or revenue; business
            interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; or
            any indirect or consequential loss or damage.
          </Typography>
        </div>

        <div className={classes.paragraph}>
          <Typography variant='h6'>We are not responsible for viruses</Typography>
          <Typography variant='body2'>
            We do not guarantee that our platform will be secure or free from bugs or viruses. You are
            responsible for configuring your information technology, computer programs and platform to access
            our platform. You should use your own virus protection software. You must not misuse our platform
            by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious
            or technologically harmful. You must not attempt to gain unauthorized access to our site, the
            server on which our site is stored or any server, computer or database connected to our site. You
            must not attack our site via a denial-of-service attack or a distributed denial-of service attack.
            We will report any such breach to the relevant law enforcement authorities and we will cooperate
            with those authorities by disclosing your identity to them. In the event of such a breach, your
            right to use our platform will cease immediately.
          </Typography>
        </div>

        <div className={classes.paragraph}>
          <Typography variant='h6'>Our trademark</Typography>
          <Typography variant='body2'>
            We are the registered proprietor of the figurative trademark “Delivery Hero RED COMET” and the
            registered word mark “DELIVERY HERO”. You are not permitted to use them without our prior written
            approval.
          </Typography>
        </div>
      </div>
      <div className={classes.btnWrap}>
        <Button type='button' onClick={close} className={classes.btn}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default observer(TermsAndConditions);
