import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './helpers/ProtectedRoute';
import Login from './views/Login';
import Recovery from './views/Recovery';
import Main from './views/Main';
import SetPassword from './views/SetPassword';
import OTPLogin from './views/OTPLogin';

const App: React.FC = () => {
  return (
    <div className='App' data-testid='insights-platform-webapp'>
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/otp-login' component={OTPLogin} />
          <Route path='/set-password' component={SetPassword} />
          <Route path='/recovery' component={Recovery} />
          <ProtectedRoute path='/:category/:frame' component={Main} />
          <ProtectedRoute path='/:category' component={Main} />
          <ProtectedRoute path='/*' component={Main} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
