import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { DHTextLogo } from '../../components/DHLogo';
import BgImage from './bg.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
    paddingTop: theme.spacing(32),
    paddingBottom: theme.spacing(32),
    backgroundImage: `url(${BgImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  logoContainer: {
    width: '572px',
    marginBottom: theme.spacing(15),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  insightsPlatformSign: {
    fontSize: '43px',
    paddingLeft: theme.spacing(8),
  },
}));

interface TLoginFormContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

const LoginFormContainer: React.FC<TLoginFormContainerProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <DHTextLogo primary />
        <Typography variant='h4' component='h2' className={classes.insightsPlatformSign}>
          Insights Platform
        </Typography>
      </div>

      {children}
    </div>
  );
};

export default LoginFormContainer;
