import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';

import { useAuthContext, useInsightsContext } from '../../init';
import { UrlParams } from '../../types';
import { TLoginData, LOGIN_RESPONSE_RESULT } from '../../lib/insightsApi';

import Page from '../../layout/Page';
import Loader from '../../components/Loader';
import Link from '../../components/Link';
import ErrorMessage from '../../components/ErrorMessage';
// import InvertedButton from '../../components/Button/InvertedButton';
import LoginPageContainer from './LoginPageContainer';
import LoginFormContainer from './LoginForm/LoginFormContainer';
import LoginForm from './LoginForm';

interface TEvent {
  target: { value: string; name: string };
}

// const useStyles = makeStyles((theme) => ({
//   marginBottom: {
//     marginBottom: theme.spacing(5),
//   },
// }));

const Login: React.FC = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const auth = useAuthContext();
  const history = useHistory();

  if (auth.isAuthenticated()) {
    history.push('/');
  }

  const urlParams = useParams<UrlParams>();
  const insights = useInsightsContext();
  const location = useLocation();
  // const classes = useStyles();

  const loginAction = async (username: string, password: string) => {
    setLoading(true);
    setError('');
    const data: TLoginData = { username, password };

    try {
      const result = await auth.login(data);

      switch (result) {
        case LOGIN_RESPONSE_RESULT.RESET_PASSWORD:
          history.push(`/set-password?email=${encodeURIComponent(form.email)}`);
          break;
        case LOGIN_RESPONSE_RESULT.LOGIN:
        default:
          await insights.initialize(urlParams.category ?? '', urlParams.frame ?? '');
          history.push('/');
          break;
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onLoginFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    loginAction(form.email, form.password);
  };

  const handleInputChange = (e: TEvent): void => {
    setForm({ ...form, [e.target.name]: e.target.value.trim() });
  };

  // const handleClick = () => {
  //   history.push('/otp-login');
  // };

  return (
    <Page>
      <LoginPageContainer>
        <LoginFormContainer>
          {location.hash === '#password-changed' && (
            <p>Password has been successfully changed. Please login.</p>
          )}
          <LoginForm handleInputChange={handleInputChange} handleFormSubmit={onLoginFormSubmit} />
          {/* TODO: add direct link to request magic link later */}
          {/* <InvertedButton className={classes.marginBottom} fullWidth onClick={handleClick}>
            Login without password
          </InvertedButton> */}
          <ErrorMessage error={error} />
          <Link to='/recovery'>Create or change your password</Link>
        </LoginFormContainer>
      </LoginPageContainer>

      <Loader open={loading} />
    </Page>
  );
};

export default Login;
