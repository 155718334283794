import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  logout: {
    border: 'none',
    background: 'none',
    cursor: 'pointer'
  }
}))

interface TLogoutButtonProps {
  onClick: () => void
}

const LogoutButton: React.FC<TLogoutButtonProps> = ({ onClick }) => {
  const classes = useStyles()
  return (
    <button className={classes.logout} onClick={onClick} aria-label='Logout'>
      <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M24.0232 26.7676H1.27319V5.76758H7.83569V6.64258H2.14819V25.8926H23.1482V18.4551H24.0232V26.7676Z' fill='white' />
        <path d='M6.52319 19.751V18.4551C6.52319 8.73384 11.5155 6.97159 17.0232 6.66359V1.7229L26.8648 10.5801L17.0232 19.4373V14.5171C11.561 14.5548 9.30875 16.0948 7.3085 18.7198L6.52319 19.751ZM17.2367 13.6417C17.3119 13.6417 17.3876 13.6417 17.4642 13.6426L17.8986 13.6456V17.4729L25.5571 10.5801L17.8986 3.68728V7.49878L17.4799 7.51715C11.7303 7.76521 7.80551 9.54715 7.42838 17.2008C9.42732 14.9809 12.0182 13.6417 17.2367 13.6417Z' fill='white' />
      </svg>
    </button>
  )
}

export default LogoutButton
