import React from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useAuthContext, useInsightsContext } from '../../init';

import Navigation from './Navigation';
import { DHCometLogo } from '../../components/DHLogo';
import LogoutButton from './LogoutButton';

const existingBrandLogos = ['coca-cola', 'pepsico', 'unilever'];

const useStyles = makeStyles((theme) => ({
  header: {
    height: theme.sizes.headerHeight,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    flexFlow: 'nowrap',
  },
  headerLeft: {
    height: '100%',
  },
  logo: {
    width: '50px',
  },
  title: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
  },
  brandLogoContainer: {
    width: '92px',
    marginRight: theme.spacing(2),
  },
  brandLogo: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  const auth = useAuthContext();
  const history = useHistory();
  const insightsStore = useInsightsContext();

  const handleLogout = (): void => {
    insightsStore.resetStates();
    auth.logout().then(() => {
      history.push('/login');
    });
  };

  const brandName = insightsStore.brandName;
  const brandHasLogo = existingBrandLogos.includes(brandName);
  const brandLogo = brandHasLogo ? `/${brandName}.png` : null;

  return (
    <Grid container alignItems='center' className={classes.header}>
      <Grid container item alignItems='center' className={classes.headerLeft}>
        <Grid item className={classes.logo}>
          <DHCometLogo />
        </Grid>
        <Grid item>
          <Typography variant='h5' component='h1' className={classes.title}>
            Insights Platform
          </Typography>
        </Grid>
        <Grid item>
          <Navigation insightsStore={insightsStore} />
        </Grid>
      </Grid>

      <Grid item className={classes.rightSection}>
        {brandLogo && (
          <Grid item className={classes.brandLogoContainer}>
            <img src={brandLogo} alt={`${insightsStore.brandName} logo`} className={classes.brandLogo} />
          </Grid>
        )}
        <Grid item>
          <LogoutButton onClick={handleLogout} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(Header);
