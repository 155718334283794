import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const Input = withStyles((theme) => ({
  input: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: theme.border.light,
    fontSize: '16px',
    height: '48px',
    paddingLeft: '8px',
    paddingRight: '8px',
    boxSizing: 'border-box',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase)

export default Input
