import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import CssBaseline from '@material-ui/core/CssBaseline';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    border: {
      light: string;
    };
    red: {
      main: string;
      darkish: string;
    };
    gray: {
      main: string;
      light: string;
      lighten: string;
    };
    sizes: {
      headerHeight: string;
      footerHeight: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    border: {
      light: string;
    };
    red: {
      main: string;
      darkish: string;
    };
    gray: {
      main: string;
      light: string;
      lighten: string;
    };
    sizes: {
      headerHeight: string;
      footerHeight: string;
    };
  }
}

export const WHITE = '#FFFFFF';
export const RED = '#D62028';
export const GRAY = '#343B46';
export const CONTENT_BACKGROUND = '#F5F5F6';

export const theme = createMuiTheme({
  border: {
    light: '1px solid #DEDEDE',
  },
  palette: {
    primary: {
      main: RED,
    },
    text: {
      primary: GRAY,
    },
  },
  red: {
    main: '#D62028',
    darkish: '#B91D24',
  },
  gray: {
    main: GRAY,
    lighten: lighten(GRAY, 0.82),
    light: lighten(GRAY, 0.7),
  },
  sizes: {
    headerHeight: '68px',
    footerHeight: '36px',
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
});

interface TMuiThemeProps {
  children: React.ReactNode | React.ReactNode[];
}

const MuiTheme: React.FC<TMuiThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MuiTheme;
