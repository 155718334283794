import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useFwFContext } from '../../init';
import { PortalFrame } from '../../types';
import { FWF_FLAGS } from '../../stores/fwfStore';
import { IInsightsStore } from '../../stores/insightsStore';
import Loader from '../Loader';
import FiltersControls from '../FiltersControls';
import Iframe from '../Iframe';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
  },
  iframe: {
    height: 'calc(100% - 40px)', // 60 height of cta buttons - 20 margin
  },
  info: {
    padding: theme.spacing(3),
    fontSize: 20,
  },
}));

interface IframeProps {
  store: IInsightsStore;
}

const LOOKER_EVENT_TYPE = {
  DASHBOARD_RUN_START: 'dashboard:run:start', // triggers on dashboard reload and initial run
  DASHBOARD_LOADED: 'dashboard:loaded', // triggers before run after category change
  DASHBOARD_FILTERS_CHANGED: 'dashboard:filters:changed', // triggers on filters change and reset
  PAGE_CHANGED: 'page:changed',
};

const LOOKER_POST_EVENT_TYPE = {
  DASHBOARD_FILTERS_UPDATE: 'dashboard:filters:update',
  DASHBOARD_RUN: 'dashboard:run',
};

const IframeContainer: React.FC<IframeProps> = ({ store }) => {
  const classes = useStyles();
  const [frame, setFrame] = useState<PortalFrame | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const fwf = useFwFContext();
  const { [FWF_FLAGS.SHOW_CTA_FILTERS_BUTTONS]: showCTAButtons } = fwf.ff;

  const [defaultFilters, setDefaultFilters] = useState<Record<string, string>>({});
  const [filters, setFilters] = useState<Record<string, string>>(defaultFilters);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    setHasChanges(false);
    setIsDefault(true);

    const params = {
      [FWF_FLAGS.SHOW_CTA_FILTERS_BUTTONS]: showCTAButtons,
    };
    store
      .getNewFrame(params)
      .then((newFrame) => {
        setLoading(false);
        setFrame(newFrame);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }, [store.selectedFrame, showCTAButtons]);

  const postMessage = (message: string) => {
    const targetOrigin = frame?.url || '*';
    iframeRef?.current?.contentWindow?.postMessage(message, targetOrigin);
  };

  useEffect(() => {
    const isEqualToDefault = JSON.stringify(filters) === JSON.stringify(defaultFilters);
    setIsDefault(isEqualToDefault);
  }, [filters, defaultFilters]);

  useEffect(() => {
    if (frame && showCTAButtons) {
      const handler = (event: MessageEvent) => {
        // listen only for actions from looker
        const lookerOrigin = frame ? new URL(frame.url).origin : '';
        const isLookerEvent = event.origin === lookerOrigin;
        const isIframeSource = event.source === iframeRef?.current?.contentWindow;

        if (isIframeSource && isLookerEvent) {
          const data = JSON.parse(event.data);

          switch (data.type) {
            case LOOKER_EVENT_TYPE.DASHBOARD_FILTERS_CHANGED:
              setFilters(data.dashboard.dashboard_filters);
              setHasChanges(true);
              break;
            case LOOKER_EVENT_TYPE.DASHBOARD_LOADED:
              const newDefaultFilters = data.dashboard.dashboard_filters;
              setDefaultFilters(newDefaultFilters);
              setFilters(newDefaultFilters);
              setHasChanges(false);
              break;
            default:
              break;
          }
        }
      };

      window.addEventListener('message', handler, false);

      return () => window.removeEventListener('message', handler);
    }
  }, [frame, showCTAButtons]);

  const applyFilters = (filters: Record<string, string>) => {
    postMessage(
      JSON.stringify({
        type: LOOKER_POST_EVENT_TYPE.DASHBOARD_FILTERS_UPDATE,
        filters,
      })
    );
    postMessage(
      JSON.stringify({
        type: LOOKER_POST_EVENT_TYPE.DASHBOARD_RUN,
      })
    );
  };

  const applySelectedFilters = () => {
    applyFilters(filters);
    setHasChanges(false);
  };

  const resetAllFilters = () => {
    applyFilters(defaultFilters);
    setHasChanges(false);
  };

  if (store.isLoaded && store.selectedCategory === '') {
    return <div className={classes.info}>No reports available</div>;
  }

  if (!store.isLoaded || loading) {
    return <Loader open />;
  }

  if (!showCTAButtons) {
    return <Iframe frame={frame} ref={iframeRef} isHidden={store.isReportHidden} />;
  }

  if (showCTAButtons) {
    const title = frame ? store.frames.find((item) => item.identifier === frame.identifier)?.name : undefined;

    return (
      <div className={classes.wrapper}>
        <FiltersControls
          title={title}
          onApply={applySelectedFilters}
          onReset={resetAllFilters}
          hasChanges={hasChanges && !isDefault}
          isDefault={isDefault}
        />
        <Iframe frame={frame} ref={iframeRef} isHidden={store.isReportHidden} className={classes.iframe} />
      </div>
    );
  }

  return null;
};

export default observer(IframeContainer);
