import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { useAuthContext } from '../../init';

interface TProtectedRouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path: string;
  exact?: boolean;
}

const ProtectedRoute: React.FC<TProtectedRouteProps> = ({ component, path, exact }) => {
  const auth = useAuthContext();
  if (!auth.isAuthenticated()) return <Redirect to='/login' />;

  return <Route path={path} component={component} exact={exact} />;
};

export default ProtectedRoute;
