import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IInsightsStore } from '../../stores/insightsStore';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    color: theme.palette.common.white,
    fontSize: '12px',
    display: 'flex'
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(5)
  }
}));

interface FooterLinksProps {
  store: IInsightsStore
};

const FooterLinks: React.FC<FooterLinksProps> = ({ store }) => {
  const classes = useStyles();

  const openTermsAndConditions = (): void => {
    store.openTermsAndConditions();
  };

  if (store.isTermsAndConditionsOpen || (store.kpiDefinitionUrl !== '')) {
    return null;
  }

  return (
    <div className={classes.wrapper} data-testid='footer-links'>
      <div className={classes.item} onClick={openTermsAndConditions}>
        <span>Terms and conditions</span>
      </div>
    </div>
  );
}

export default FooterLinks;
