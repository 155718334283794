import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getSearchParameterWithoutEncoding } from '../../helpers';
import Page from '../../layout/Page';
import RecoveryPageContainer from './RecoveryPageContainer';
import RecoveryForm from './RecoveryForm';
import Loader from '../../components/Loader';
import Link from '../../components/Link';
import ErrorMessage from '../../components/ErrorMessage';

import { useAuthContext } from '../../init';

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
}));

const SuccessMessage = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.marginBottom}>
      Recovery email has been successfully sent. Please follow the&nbsp;instructions to&nbsp;recover your
      account.
    </Typography>
  );
};

interface TEvent {
  target: { value: string; name: string };
}

const Recovery: React.FC = () => {
  const auth = useAuthContext();
  const history = useHistory();

  if (auth.isAuthenticated()) {
    history.push('/');
  }

  const location = useLocation();

  const [form, setForm] = useState({ email: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    // we don't use URLSearchParams for email, because it isn't encoded in url from emails
    const email = getSearchParameterWithoutEncoding('email', location.search);

    if (email) {
      setForm({ email });
    }
  }, []);

  const sendRecoveryEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsSent(false);
    setLoading(true);

    try {
      await auth.sendRecoveryEmail(form.email);
      setIsSent(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: TEvent): void => {
    setForm({ ...form, [e.target.name]: e.target.value.trim() });
  };

  return (
    <Page>
      <RecoveryPageContainer>
        {isSent ? (
          <SuccessMessage />
        ) : (
          <React.Fragment>
            <p>
              Use this form to&nbsp;request an&nbsp;email to&nbsp;help you restore your password or&nbsp;set
              a&nbsp;new one.
            </p>

            <Typography variant='body2'>Please enter your email address.</Typography>

            <RecoveryForm
              formValue={form}
              handleInputChange={handleInputChange}
              handleFormSubmit={sendRecoveryEmail}
            />

            <ErrorMessage error={error} />
          </React.Fragment>
        )}

        <Link to='/login'>Back to login page</Link>
      </RecoveryPageContainer>

      <Loader open={loading} />
    </Page>
  );
};

export default Recovery;
