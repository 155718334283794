import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  errorText: {
    marginBottom: theme.spacing(5),
    color: theme.red.main,
  },
}));

interface TErrorMessage {
  error?: string;
}

const ErrorMessage: React.FC<TErrorMessage> = ({ error }) => {
  const classes = useStyles();

  if (error === undefined) return null;

  return (
    <Typography align='center' className={classes.errorText} data-testid='error-message'>
      {error}
    </Typography>
  );
};

export default ErrorMessage;
