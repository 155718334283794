import React from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IInsightsStore } from '../../../stores/insightsStore'
import NavigationItem from './NavigationItem'

const useStyles = makeStyles(
  (theme: Theme) => {
    return createStyles({
      navigation: {
        marginLeft: theme.spacing(4)
      }
    })
  }
)

interface TNavigationProps {
  insightsStore: IInsightsStore
}

const Navigation: React.FC<TNavigationProps> = ({ insightsStore }) => {
  const navigationCategories = insightsStore.navigationCategories
  const classes = useStyles()
  const history = useHistory()

  if (navigationCategories.length === 0) {
    return null;
  }

  const handleCategoryChange = (category: string): void => {
    insightsStore.onCategoryChange(category).then(() => {
      history.push(insightsStore.getUrl())
    }).catch((error: Error) => {
      console.error(error)
    })
  }

  return (
    <div className={classes.navigation} data-testid='navigation'>
      {navigationCategories.map(category => (
        <NavigationItem
          key={category.id}
          label={category.display_name}
          value={category.id}
          disabled={!category.enabled}
          handleCategoryChange={handleCategoryChange}
          selected={insightsStore.selectedCategory === category.id}
        />
      ))}
    </div>
  )
}

export default observer(Navigation)
