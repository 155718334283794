import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { IInsightsStore } from '../../stores/insightsStore';
import { PortalFrameMenuItem } from '../../types';

import Item from './Item';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(3),
    overflow: 'auto'
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,

    '& + div': {
      marginLeft: theme.spacing(3)
    }
  }
}));

interface NavigationProps {
  store: IInsightsStore
}

interface framesByGroup {
  reportGroupName: string,
  reportGroup: string,
  frames: PortalFrameMenuItem[]
}

const Navigation: React.FC<NavigationProps> = ({ store }) => {
  const classes = useStyles();
  const history = useHistory();

  const onItemClick = (id: string): void => {
    store.onFrameChange(id);
    history.push(store.getUrl());
  };

  const frames = store.frames.reduce<framesByGroup[]>((arr,frame) => {
    const currentGroup = arr.find((item) => item.reportGroup === frame.reportGroup);
    
    if (currentGroup) {
      const index = arr.indexOf(currentGroup);
      arr[index].frames.push(frame);
    } else {
      arr.push({
        reportGroupName: frame.reportGroupName,
        reportGroup: frame.reportGroup,
        frames: [ frame ]
      });
    }
    return arr;
  }, []);

  return (
    <div className={classes.wrapper}>
      {frames.map((group, index) => (
        <React.Fragment key={index}>
          {group.reportGroupName && <div className={classes.title}>{group.reportGroupName}</div>}

          <div>
            {group.frames.map((frame) => (
              <Item
                key={frame.identifier}
                entity={frame}
                isActive={store.selectedFrame === frame.identifier}
                onClick={onItemClick}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default observer(Navigation);