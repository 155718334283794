import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(5), //TODO: change to 3, after magic link login
  },
}));

interface TEvent {
  target: { value: string; name: string };
}

interface TLoginFormProps {
  handleInputChange: (e: TEvent) => void;
  handleFormSubmit: (e: React.FormEvent) => void;
}

const LoginForm: React.FC<TLoginFormProps> = ({ handleInputChange, handleFormSubmit }) => {
  const classes = useStyles();

  return (
    <form autoComplete='off' onSubmit={handleFormSubmit} data-testid='login-form'>
      <Grid container direction='column'>
        <FormControl className={classes.marginBottom}>
          <Input
            placeholder='E-mail'
            inputProps={{ 'aria-label': 'e-mail' }}
            type='email'
            name='email'
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl className={classes.marginBottom}>
          <Input
            placeholder='Password'
            inputProps={{ 'aria-label': 'password' }}
            type='password'
            name='password'
            onChange={handleInputChange}
          />
        </FormControl>
      </Grid>
      <Button type='submit' fullWidth className={classes.marginBottom}>
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
