import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import { IInsightsStore } from '../../stores/insightsStore';

import QuestionMark from './QuestionMark';
import UserGuideIcon from './UserGuideIcon';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: `${theme.spacing(3)}px 0`,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1), 
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '& svg': {
      fill: theme.palette.text.primary,
    },

    '&:hover': {
      color: theme.palette.primary.main,

      '& svg': {
        fill: theme.palette.primary.main,
      }
    }
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(2),
  }
}));

interface NavigationProps {
  store: IInsightsStore
};

const SidebarLinks: React.FC<NavigationProps> = ({ store }) => {
  const classes = useStyles();

  const openKPIDefinition = (): void => {
    store.loadKPIDefinition();
  };

  const openUserGuide = async (): Promise<void> => {
    let userGuideUrl = await store.getUserGuideUrl();
    window.open(userGuideUrl, '_blank');
  };

  return (
    <div className={classes.wrapper} data-testid='sidebar-links'>
      <div className={classes.item} onClick={openUserGuide}>
        <span className={classes.icon}><UserGuideIcon /></span>
        <span>User Guide</span>
      </div>
      <div className={classes.item} onClick={openKPIDefinition}>
        <span className={classes.icon}><QuestionMark /></span>
        <span>KPI's Definitions</span>
      </div>
    </div>
  );
}

export default observer(SidebarLinks);