import { TProxyProviderFn } from './RequestProxyFactory';

export interface IPortalNavigationItem {
  display_name: string;
  enabled: boolean;
  id: string;
}

export interface IPortalFrameMenuItem {
  id: string;
  menu_display_name: string;
  menu_icon_name: string;
  menu_display_order: number;
  report_group: string;
  report_group_display_name: string;
}

export interface IPortalFrame {
  id: string;
  embed_url: string;
}

export interface IPortalNavigationResponseData {
  brand: string;
  categories: IPortalNavigationItem[];
  platform_id: string;
}

export interface IPortalNavigationResponse {
  data: IPortalNavigationResponseData;
}

export interface LoginResponse {
  result: string;
  authToken?: string | undefined;
  refreshToken?: string | undefined;
  deviceToken?: string | undefined;
  resetPasswordToken?: string | undefined;
}

export interface TLoginData {
  username?: string;
  password?: string;
  token?: string;
  type?: string;
}

interface TLoginConfig {
  data: TLoginData;
}

export interface TSetPasswordData {
  username?: string;
  newPassword: string;
  token: string;
  source: string;
}

interface TSetPasswordConfig {
  data: TLoginData;
}

export const LOGIN_ACTION_TYPES = {
  MAGIC_LINK: 'MagicLink',
};

export const LOGIN_RESPONSE_RESULT = {
  LOGIN: 'login',
  RESET_PASSWORD: 'forcePasswordChange',
};

export const RESET_PASSWORD_SOURCE = {
  COGNITO: 'Cognito',
  KEYMAKER: 'Keymaker',
};

export const IS_LOGIN_FLOW_MOCKED = false;
export const AUTH_FLOW_URL = `${IS_LOGIN_FLOW_MOCKED ? '/mock' : ''}/auth`;

export interface ApiInterface {
  getPortalNavigation: () => Promise<IPortalNavigationResponseData | null>;
  getFramesByCategory: (platform: string, category: string) => Promise<IPortalFrameMenuItem[] | null>;
  getFrame: (platform: string, category: string, frameID: string) => Promise<IPortalFrame | null>;
  getKPIFrameUrl: () => Promise<string | null>;
  getUserGuide: () => Promise<null>;
  login: (data: TLoginData) => Promise<LoginResponse>;
  logout: () => Promise<any>;
  setPassword: (data: TSetPasswordData) => Promise<any>;
  sendRecoveryEmail: (username: string) => Promise<any>;
  requestMagicLink: (username: string) => Promise<any>;
}

export default class InsightsApi implements ApiInterface {
  proxy: TProxyProviderFn;

  constructor(requestAuthProxy: TProxyProviderFn) {
    this.proxy = requestAuthProxy;
  }

  async getPortalNavigation(): Promise<IPortalNavigationResponseData | null> {
    return await this.proxy('/portal/navigation', {}, 'GET')
      .then((response: IPortalNavigationResponse) => {
        return response.data;
      })
      .catch((error: Error) => {
        console.error(error);
        return null;
      });
  }

  async getFramesByCategory(platform: string, category: string): Promise<IPortalFrameMenuItem[] | null> {
    const url = `/portal/platforms/${platform}/categories/${category}/frames`;
    return await this.proxy(url, {}, 'GET')
      .then((response) => {
        return response.data.frames;
      })
      .catch((error: Error) => {
        console.error(error);
        return null;
      });
  }

  async getFrame(
    platform: string,
    category: string,
    frameID: string,
    params?: {}
  ): Promise<IPortalFrame | null> {
    const url = `/portal/platforms/${platform}/categories/${category}/frames/${frameID}`;
    const config = { params };
    return await this.proxy(url, {}, 'GET', {}, config)
      .then((response) => {
        return response.data;
      })
      .catch((error: Error) => {
        console.error(error);
        return null;
      });
  }

  async getKPIFrameUrl(): Promise<string | null> {
    return await this.proxy('/portal/kpi-frame', {}, 'GET')
      .then((response) => {
        return response.data.embed_url;
      })
      .catch((error: Error) => {
        console.error(error);
        return null;
      });
  }

  async getUserGuide(): Promise<any> {
    const url = '/portal/user-guide';
    return await this.proxy(url, {}, 'GET', {}, { responseType: 'blob' })
      .then((response) => {
        return response.data;
      })
      .catch((error: Error) => {
        console.error(error);
        return null;
      });
  }

  async login(data: TLoginData): Promise<LoginResponse> {
    let config: TLoginConfig = { data };

    return await this.proxy(`${AUTH_FLOW_URL}/login`, {}, 'POST', {}, config)
      .then((response) => {
        return response.data;
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          console.error(error);
          throw new Error(error);
        }
      );
  }

  async logout(): Promise<any> {
    return await this.proxy(`${AUTH_FLOW_URL}/logout`, {}, 'POST').catch((error: Error) => {
      console.error(error);
      return null;
    });
  }

  async setPassword(data: TSetPasswordData): Promise<any> {
    const config: TSetPasswordConfig = { data };

    return await this.proxy(`${AUTH_FLOW_URL}/set-password`, {}, 'POST', {}, config).catch(
      ({
        response: {
          data: { error },
        },
      }) => {
        console.error(error);
        throw new Error(error);
      }
    );
  }

  async sendRecoveryEmail(username: string): Promise<any> {
    const config = { data: { username } };

    return await this.proxy(`${AUTH_FLOW_URL}/forgot-password`, {}, 'POST', {}, config).catch(
      ({
        response: {
          data: { error },
        },
      }) => {
        console.error(error);
        throw new Error(error);
      }
    );
  }

  async requestMagicLink(username: string): Promise<any> {
    const config = { data: { username } };

    return await this.proxy(`${AUTH_FLOW_URL}/request-magiclink`, {}, 'POST', {}, config).catch(
      ({
        response: {
          data: { error },
        },
      }) => {
        console.error(error);
        throw new Error(error);
      }
    );
  }
}
