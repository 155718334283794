import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/styles'

const useStyles = makeStyles(
  (theme: Theme) => {
    const navItem: CSSProperties = {
      color: theme.palette.primary.contrastText,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: 'none',
      position: 'relative',
      padding: `0 ${theme.spacing(1)}px`,
      cursor: 'pointer'
    }

    return createStyles({
      navItem,

      active: {
        ...navItem,

        '&::before': {
          content: '""',
          position: 'absolute',
          right: 0,
          bottom: '-5px',
          width: '100%',
          height: '2px',
          backgroundColor: theme.palette.primary.contrastText
        }
      },

      disabled: {
        ...navItem,
        opacity: '.5'
      }
    })
  }
)

interface TNavigationItemProps {
  label: string
  value: string
  disabled: boolean
  selected: boolean
  handleCategoryChange: (category: string) => void
}

const NavigationItem: React.FC<TNavigationItemProps> = ({
  label,
  value,
  disabled,
  selected,
  handleCategoryChange
}) => {
  const classes = useStyles()

  if (label === '') return null

  if (disabled) return <span className={classes.disabled} data-testid='navigation-item-disabled'>{label}</span>

  return (
    <span
      className={selected ? classes.active : classes.navItem}
      onClick={() => handleCategoryChange(value)}
      data-testid='navigation-item'
    >
      {label}
    </span>
  )
}

export default NavigationItem
